import React from "react";
import { RiEdit2Line } from "react-icons/ri";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import { InputText } from "primereact/inputtext";
import styled, { css } from "styled-components";
import { Dropdown } from "primereact/dropdown";

function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  str = str.replace(/_/g, " ");
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${COLORSCHEMEA.THEMEFONT};
      font-weight: bold;
    `}
`;
export default function ClientDetailsCard({
  name,
  details,
  title,
  edit,
  Icon,
  subDetails,
  maxLength = "unset",
  type,
  placeholder,
  color = "unset",
  input,
  brokerList = [],
  brokerId = {},
  dropDown,
  value,
  onChange,
  onClick,
  disabled,
}) {
  React.useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.preventDefault();
      }
    };
    const handleArrowKeys = (e) => {
      if (
        e.target.type === "number" &&
        (e.key === "ArrowUp" || e.key === "ArrowDown")
      ) {
        e.preventDefault();
      }
    };

    // Attach non-passive event listeners
    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("keydown", handleArrowKeys);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        margin: 10,
        justifyContent: "flex-end",
        height: 50,
        alignItems: "center",
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
      }}
    >
      <div
        style={{
          width: 50,
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: COLORSCHEMEA.THEMEFONT,
        }}
      >
        {Icon && <Icon size={25} />}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          fontWeight: "bold",
          color: COLORSCHEMEA.THEMEGRAY,
        }}
      >
        {capitalizeFirstLetter(title)}
      </div>
      {edit && (
        <div
          style={{
            width: 70,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onClick}
        >
          <RiEdit2Line size={20} color={COLORSCHEMEA.THEMEFONT} />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          paddingRight: 10,
          fontWeight: "bold",
          flexDirection: "column",
          textTransform: "uppercase",
        }}
      >
        {!input ? (
          <>
            <div style={{ color: color }}> {details}</div>
            {subDetails && (
              <div style={{ fontSize: 10, color: COLORSCHEMEA.THEMEGRAY }}>
                {subDetails}
              </div>
            )}
          </>
        ) : (
          <InputText
            type={type}
            value={value}
            name={name}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            style={{
              width: "100%",
              paddingRight: 5,
              borderWidth: 0.2,
              backgroundColor: disabled ? "#edebfa" : "initial",
              color: disabled ? COLORSCHEMEA.THEMEFONT : "initial",
              // fontWeight: disabled ? "bold" : "normal",
            }}
            disabled={disabled}
            // maxLength={maxLength}
            autoFocus
          />
        )}

        {dropDown && (
          <Dropdown
            style={{
              width: "100%",
              height: "40px",
              minWidth: 200,
            }}
            onChange={(e) => {
              onChange(e.value);
            }}
            options={brokerList}
            value={brokerId}
            name={name}
            optionLabel="username"
            placeholder={placeholder}
            filter
            showClear
          />
        )}
      </div>
    </div>
  );
}
